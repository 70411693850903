@import "../../../style/focuswin.variables.base.scss";

.footer {
    background-color: #fff;
    z-index: 15;
    position: relative;
    .fw-footer-header {
        padding: 40px 120px 60px;
        border-bottom: 1px solid rgba(0, 0, 0, .15);
        box-shadow: 0 0 4px rgba(0, 0, 0, .25);
    }
    .fw-footer-header-title {
        font-size: 40px;
        font-weight: 800;
        color: rgba(0, 0, 0, .87);
        margin-bottom: 10px;
    }
    .fw-footer-header-detail {
        font-size: 16px;
        color: rgba(0, 0, 0, .67);
    }
    .fw-footer-header-service {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        color: rgba(0, 0, 0, .67);
    }
    .fw-footer-header-service-numb {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }
    .service-title {
        font-weight: 600;
    }
    .service-detail {
        margin: 0 20px;
    }
    .fw-footer-header-service-time {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        color: rgba(0, 0, 0, .67);
        margin-top: 5px;
    }
    .fw-footer-footer {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .fw-footer-copy-text {
            color: rgba(0, 0, 0, .67);
        }
    }

    .fw-footer-term {
        font-size: 18px;
        .fw-term-link {
            transition: all .4s;
            background-color: transparent !important;
            box-shadow: none !important;
            color: #54c2ba;
            text-decoration: underline;
            .dx-button-content {
                padding: 0 !important;
            }
        }
        .fw-term-link:hover {
            color:rgb(140, 209, 200);
        }
        .fw-term-link:not(:first-child) {
            margin-left: 30px;
        }
    }
}