.side-nav-inner-toolbar {
  width: 100%;
}

#navigation-header {
  height: 100px;
  transition: all .6s;
  .dx-drawer-opened & {
    background-color: rgb(35, 37, 37);
    .dx-toolbar-after {
      display: table;
    }
  }
  .dx-toolbar-after {
    margin-right: 20px;
    display: none;
  }
  .dx-toolbar-items-container {
    height: 100px;
    .dx-item.dx-toolbar-item.dx-toolbar-button.menu-button {
      width: 100px;
      padding: 0;
      .dx-item-content.dx-toolbar-item-content {
        width: 100px;
        display: flex;
        justify-content: center;
        .dx-button {
          transition: all .4s;
          .dx-icon {
            font-size: 36px;
            color: rgba(255, 255, 255, .8);
          }
          &.dx-state-hover {
            background-color: transparent;
            filter: opacity(.7)
          }
          &.dx-state-focused {
            background-color: transparent;
          }          
        }
      }
    }
    .comp-imag {
      height: 28px;
      filter: brightness(0) saturate(100%) invert(1);
      transition: all .4s;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
  }

  .screen-x-small & {
    padding-left: 20px;
  }

  .dx-theme-generic & {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 10000;
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  &.active {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}