@import "../../../../dx-styles.scss";
@import "../../../style/focuswin.variables.base.scss";

.dx-drawer-content {
  padding: 0 !important;
}

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 100%;
  border-right: 1px solid rgba(150, 150, 150, .5);
  background-color: rgba(0, 0, 0, .8);
  .dx-drawer-opened & {
    border-right: none;
  }

  .menu-container {
    min-height: 100%;
    display: flex;
    flex: 1;
    transition: all .6s;
    .dx-drawer-opened & {
      background-color: rgb(35, 37, 37);
    }

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;

      // ## Icon width customization
      .dx-treeview-item {
        padding: 15px 16px;
        padding-left: 0;
        flex-direction: row-reverse;
        color: #383838;
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 18px;
        color: rgba(255, 255, 255, .8);
        .dx-item-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          line-height: 1;
          span {
            height: 32px;
            display: flex;
            align-items: center;
            margin-left: 12px;
          }
        }
        .dx-icon {
          width: 100px !important;
          background-position: 50%;
          margin: 0 !important;
          font-size: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(255, 255, 255, .8);
        }
      }
      
      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
        position: relative;
        .dx-treeview-node.dx-state-selected {
          &::before {
            content: "";
            position: absolute;
            width: 1%;
            height: 100%;
            top:0;
            left: 0;
            background-color: $primary-accent-color;
          }
        }
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }


      // ## 트리 아이템 레벨별 커스텀
      .dx-treeview-node {
        &[aria-level='1'] {
          font-weight: bold;
          border-bottom: 1px solid rgba(150, 150, 150, .25);    
        }
  
        &[aria-level='2'] .dx-treeview-item-content {
          font-weight: normal;
          font-size: 16px;
          padding-left: 100px;
        }

        &[aria-level='3'] .dx-treeview-item-content {
          font-weight: normal;
          padding-left: 80px;
        }

        &[aria-level='4'] .dx-treeview-item-content {
          font-weight: normal;
          padding-left: 100px;
        }        
      }
      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused)> .dx-treeview-item {
            background-color: rgb(207, 208, 235, .1);
          }

          &.dx-state-selected > .dx-treeview-item * {
            color: $primary-accent-color;
          }

          &:not(.dx-state-focused)>.dx-treeview-item.dx-state-hover {
            background-color: rgba(82, 176, 192, 0.3);
            transition: all .4s;
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused > .dx-treeview-item * {
        color: inherit;
      }
    }
    // ##
  }
}

.dx-treeview-toggle-item-visibility {
  color: rgba(255, 255, 255, 0.54);
}

.dx-scrollable-content > ul.dx-treeview-node-container {
  padding-bottom: 100px;
}

.menu-container .dx-scrollable-container {
  overflow: auto;
}

/* 웹킷 기반 브라우저에서 스크롤바 숨기기 */
.menu-container .dx-scrollable-container::-webkit-scrollbar {
  display: none;
}

/* 파이어폭스에서 스크롤바 숨기기 */
.menu-container .dx-scrollable-container {
  scrollbar-width: none; /* Firefox */
}

/* 완전히 스크롤바 숨기기 */
.menu-container .dx-scrollable-container {
  -ms-overflow-style: none; /* IE, Edge */
}

.dx-drawer-overlap.pre-init-blink-fix {
  @import "../../../../utils/patches.scss";
  @include menu-pre-init-patch;
}