@import "./focuswin.variables.base.scss";

.dx-scrollable-container {
    overflow: visible;
}

.fw-column-flex-box {
    display: flex;
    flex-direction: column;
};

.fw-row-flex-box {
    display: flex;
    flex-direction: row;
};

.fw-single-card {
    margin: auto auto;
};

.app .content {
    background-color: $base-bck-color;
};

.app .content-block {
    background-color: var(--primary-depth-color);
    margin: 0;
}

.dx-list-item-content .dx-item-content.dx-toolbar-item-content{
    display: flex;
    flex-direction: column;
    & > .dx-widget {
        width: 200px !important;
    }
    .dx-checkbox {
        &:not(:first-child) {
            margin-top: 10px;
        } 
    }

    .dx-editor-outlined {
        &:not(:first-child) {
            margin-top: 5px;
        }
    }
}

// page
.fw-grid-page {
    width: 100%;
    height: 100%;
}

.triple-grid-page > div:first-child {
    margin-bottom: 20px;
}

#fw-page-content {
    width: 100%;
    overflow-x: auto;

    .fw-half-content {
        display: flex;
        justify-content: space-between;
        height: 100%;
        &.half {
            width: 50%;
            &:not(:first-child) {
                padding-left: 5px;
            }
            &:not(:last-child) {
                padding-right: 5px;
            }            
        }
        &.full {
            width: 100%;
            .fw-scroll-grid:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
};

.fw-dual-chart-content {
    padding-top: 20px;
    width: 100%;
    .fw-chart-content {
        width: calc(50% - 5px);
        &:first-child {
            margin-right: 5px;
        }   
        &:last-child {
            margin-left: 5px;
        }
    }
}

.screen-x-small, .screen-small, .screen-medium {
    #fw-page-content {
        height: 100%;
        margin-bottom: 40px;
        .fw-dual-chart-content {
            .fw-chart-content {
                width: 100%;
                margin: 0;
            }
        }
    }    
}

.dx-scrollable-content {
    .dx-empty-message {
        margin-top: 10px;
        text-align: center;
    }
};

.status {
    display: inline-flex;
    align-items: center;
};

.status-gray {
    --color: #575757;
};

.status-red {
    --color: #ed4832;
};

.status-green {
    --color: #2cb52c;
};

.status::before {
    --diameter: 10px;
    content: " ";
    background-color: var(--color);
    border-radius: calc(var(--diameter) / 2);
    display: inline-block;
    height: var(--diameter);
    margin-right: calc(var(--diameter) / 2);
    width: var(--diameter);
    content: " ";
};

.dx-form.plain-styled-form .dx-layout-manager .dx-field-item:not(.dx-first-col) {
    padding-left: 0;
};

.fw-warning-message {
    color: #000;
}

.dx-texteditor.dx-editor-filled::after {
    border-bottom: 1px solid rgb(150, 150, 150, .5);
}

.dx-texteditor.dx-editor-filled.dx-state-hover::after {
    border-bottom: 1px solid rgb(150, 150, 150, .8);
}

.dx-button-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.only-title {
    font-size: 18px;
    padding: 15px;
};

// notify
.dx-overlay-content.dx-toast-content {
    // warning
    &.dx-toast-warning  {
        background-color: #ff7707;
    }
    // info
    &.dx-toast-info  {
        background-color: $primary-accent-color;
    }
}

// rollers order 
.dx-dateview-rollers {
    display: flex;
}

.dx-dateviewroller-day {
    order: 3;
}

.dx-dateviewroller-month {
    order: 2;
}

.dx-dateviewroller-year {
    order: 1;
}

// page load indicator
.fw-page-loader {
    position: absolute;
    z-index: 10000;
    top: 50%;
    left: 50%;
    translate: (-50%, -50%);
};


// button
.dx-button.dx-button-mode-contained {
    transition: all .4s;
    &.dx-button-default {
        background-color: var(--primary-accent-color);
        &.dx-state-hover {
            background-color: var(--secondary-accent-color);
        }
    }
}

.dx-texteditor.dx-editor-filled .dx-placeholder::before, .dx-texteditor.dx-editor-filled .dx-texteditor-input, .dx-texteditor.dx-editor-outlined .dx-placeholder::before, .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
    padding: 8px 12px;
}

.term-container {
    h1 {
        text-align: center;
        font-size: 18px;
    }
    h2 {
        font-size: 16px;
    }
    ul {
        padding-inline-start: 20px;
    }
}

.dx-toast-message {
    color: #fff;
}