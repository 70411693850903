@import '../../../style/focuswin.variables.base.scss';

.fw-profile-pays-card-container {
    position: relative;

    .fw-profile-pays-card-title {
        margin-bottom: 15px;
    }

    .fw-profile-pays-card-list-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        overflow-x: scroll;
        padding-bottom: 40px;

        &::-webkit-scrollbar {
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: rgb(226, 226, 226);
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(199, 199, 199);
        }

        &::-webkit-scrollbar-button {
            display: none;
        }
    }
}

.fw-pays-card-box {
    width: 260px;
    height: 162px;
    background-color: rgb(231, 231, 231);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    flex-shrink: 0;
    transition: all .4s;

    &:not(:first-child) {
        margin-left: 20px;
    }

    &.default {
        justify-content: center;
        align-items: center;
        transition: all .4s;

        &:hover {
            background-color: rgb(212, 212, 212);
            cursor: pointer;
        }

        &:active {
            background-color: rgb(187, 187, 187);
        }

        .fw-pays-card-add-icon {
            font-size: 24px;
        }

    }

    &.real {
        position: relative;
        justify-content: space-between;
        padding: 10px 12px;
        overflow: hidden;

        &.blue {
            background-color: rgb(104, 170, 255);
        }

        &.green {
            background-color: rgb(144, 238, 144);
        }

        &.cyan {
            background-color: rgb(48, 146, 170);
            color: #e0e0e0;

            .dx-button .dx-icon {
                color: #e0e0e0;
            }
        }

        &.purple {
            background-color: rgb(186, 85, 211);
        }

        &.orange {
            background-color: rgb(255, 165, 0);
        }

        &.pink {
            background-color: rgb(255, 182, 193);
        }

        &.black {
            background-color: rgb(54, 54, 54);
            color: #e0e0e0;

            .dx-button .dx-icon {
                color: #e0e0e0;
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: -30px;
            left: 110px;
            width: 300px;
            height: 300px;
            border-radius: 50%;
            z-index: 1;
            background-color: rgba(255, 255, 255, 0.1);
        }

        .fw-pays-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 5px;
            z-index: 5;

            .fw-pays-card-name-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;

                .fw-pays-card-name {
                    font-size: 13px;
                    margin-right: 8px;
                    max-width: 80px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .fw-pays-card-favo {
                    font-size: 20px;
                    transition: all .4s;
                    color: rgba(0, 0, 0, .4);

                    &.favo {
                        color: rgb(255, 199, 16);

                        &:hover {
                            color: rgb(214, 182, 36);
                        }
                    }

                    &:hover {
                        color: rgba(0, 0, 0, .8);
                        cursor: pointer;
                    }
                }
            }

        }

        .fw-pays-card-detail {
            z-index: 5;

            .fw-pays-card-numb {
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}