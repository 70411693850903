@import '../../../../style/focuswin.variables.base.scss';

.user-page {
    display: flex;
    flex-direction: row;
}

.fw-custom-tab {
    padding-left: 100px;
    background-color: #fff;
    box-shadow: 1px 0 4px rgba(0, 0, 0, 0.2);
    z-index: 10;
    .custom-tab-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        text-decoration: none;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        transition: all .4s;
        &:hover {
            background-color: rgb(233, 233, 233);
        }
        &::before {
            content: "";
            background-color: transparent;
            position: absolute;
            width: 4px;
            height: 100%;
            top: 0;
            right: 0;
            transition: all .4s;
        }
        &.selc::before {
            background-color: var(--primary-accent-color);
        }
        .dx-icon {
            font-size: 36px;
            margin-bottom: 5px;
        }
        .tab-title {
            font-size: 15px;
        }
    }
}