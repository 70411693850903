@import "../../../style/focuswin.variables.base.scss";

.header-component {
  display: flex;
  flex-direction: row;
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding-right: 120px;
}

.screen-x-small .dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 10px;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
  .dx-button {
    height: auto;
    transition: all .4s;

    &:hover {
      background: none;
      opacity: .6;
    }

    &:active {
      background: none;
    }
    .dx-button-content {
      padding: 0;
      font-size: 16px;
    }
  }
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button>.dx-button-content {
    padding: 3px;
  }
}

.dx-toolbar-after {
  .dx-toolbar-item-content {
    .authorization {
      margin-right: 5px;
      transition: all .4s;
      background-color: #fafbfd;
      border: 1px solid #f1f3fa;
      &.active {
        background-color: $primary-accent-color;        
      }
      &:hover {
        filter: grayscale(2);
      }
    }
  }
}

.dx-overlay-content:not(.dx-popup-fullscreen-width) {
  .dx-toolbar.dx-popup-title {
      color: #6C757D;
      .dx-item-content.dx-toolbar-item-content > div {
        font-weight: 700;
      }
      .dx-button-content {
        .dx-icon {
          color: #6C757D;
        }
      }
  }
}

.fw-content-header {
  padding: 20px 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  .header-root {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-accent-color);
    transition: all .4s;
    &:hover {
      cursor: pointer;
      filter: contrast(1.2);
      scale: 1.05;
    }
    i {
      font-size: 20px;
      color: #fff;
    }
  }

  .header-path {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    .header-path-item {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, .67);
    }
  }
}

.layout-header {
  z-index: 1000;
}

.header-component {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: transparent;
  &.top {
    height: 50px;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
  }
  &.layout {
    height: 72px;
    justify-content: left;
    background-color: transparent;
    background-color: rgba(0, 0, 0, .92);
    border-bottom: 1px solid rgba(255, 255, 255, .15);
    transition: all .4s;
    &.visible {
      background-color: rgba(0, 0, 0, .92);
    }
  }
  .header-logo {
    height: 28px;

    .logo-imag {
      height: 100%;
      &:hover {
        filter: contrast(1.2);
        cursor: pointer;
      }
    }
  }

  .header-nav-main {
    display: flex;
    flex-direction: row;
    .nav-main {
      width: 180px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .4s;
      color: rgba(255, 255, 255, .87);
      font-weight: 600;
      position: relative;
      &:hover {
        color: rgba(255, 255, 255, .87);
        cursor: pointer;
      }
      &.highlight::after {
        opacity: 1;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #62c49c;
        opacity: 0;
        transition: all 0.4s;
      }      
    }
  }

  .header-nav-subs {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    top: 72px;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: left;
    background-color: rgba(0, 0, 0, .92);
    max-height: 0;
    transition: all .4s;
    opacity: 0;
    visibility: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 15px 0;
    &.visible {
      max-height: 1000px;
      opacity: 1;
      visibility: visible;
    }
    .nav-subs-list {
      width: 180px;
      text-align: center;
      font-size: 14px;
      padding: 10px 0;
      color: rgba(255, 255, 255 ,.67);
      .nav-subs {
        padding: 8px 0;
        transition: all .4s;

        &:hover {
          color: rgba(255, 255, 255, 1);
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
  
.header-btn {
  background-color: transparent !important;
  color: rgba(0, 0, 0, .87);
  font-size: 18px;
  transition: all .8s;
  .dx-icon { 
    color: rgba(0, 0, 0, .87); 
    transition: all .4s;
  }
  .dx-button-text {
    font-weight: 800;
  }
  &.dx-state-hover {
    color: var(--primary-accent-color);
    .dx-icon { color: var(--primary-accent-color); }
  }
  &.dx-state-focused {
    color: var(--primary-accent-color);
    opacity: .8;
  }
  .dx-button-text {
    font-weight: 600;
  }
}