@import "../../../style/focuswin.variables.base.scss";

.account-container {
  width: 100vw;
  padding: 80px 0;
  background-color: #f9fdfe;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .dx-field-item .dx-field-item-content .dx-editor-outlined,
  .dx-toolbar-menu-section .dx-editor-outlined {
    border: none !important;
    box-shadow: inset 0 0 0 1px #bbbbbb;

    &.dx-state-hover {
      box-shadow: inset 0 0 0 1px #acacac;
    }

    &.dx-state-focused {
      box-shadow: inset 0 0 0 1px var(--secondary-accent-color);
    }

    &.dx-invalid {
      box-shadow: inset 0 0 0 1px rgba(244, 67, 54, .5);
    }

    &.dx-state-readonly {
      pointer-events: none;
      box-shadow: inset 0 0 0 1px #bbbbbb;
    }
  }

  .accont-card {
    position: relative;
    background-color: var(--secondary-depth-color);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .05);
    border-radius: 32px;
    z-index: 10;
    overflow: hidden;
    width: 500px;
  }

  .dx-texteditor.dx-editor-outlined {
    transition: all .4s;
    background-color: #fff;
  }
}

;

.card-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  background-color: var(--primary-accent-color);
  border-radius: 8px 8px 0 0;

  img {
    width: 300px;
    margin-bottom: 30px;
  }
}

.card-body-header-title {
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 10px;
}

;

.card-body-header-detail {
  font-size: 16px;
  color: rgba(255, 255, 255, .87);
  margin-bottom: 10px;
}

;

.card-body {
  padding: 0 36px 36px;
  position: relative;

  .account-form {
    padding: 10px 0;

    .account-panel-form {
      padding: 0 0 40px;
    }
  }

  .first-btn {
    margin-top: 32px;
    height: 38px;
  }

  .account-btn {
    margin-top: 10px;
    height: 38px;
  }

  .link {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, .57);
    font-size: 15px;

    a {
      margin-left: 8px;
    }
  }

  .dx-texteditor {
    border-radius: 8px;

    .form-editor-input {
      height: 40px;
    }
  }

  .fw-input-label {
    display: none;
  }

  .dx-texteditor.dx-editor-outlined::before {
    left: 4px;
    width: calc(100% - 8px);
  }

  .dx-item .dx-item-content .dx-field-item-content {
    font-size: 14px;

    .dx-label span {
      color: #6C757D;
      font-weight: 600;
      top: -26px;
    }

    .dx-label-before,
    .dx-label-after {
      border-top-color: #dee2e6;
      border-bottom-color: #dee2e6;
      border-inline-start-color: #dee2e6;
      border-inline-end-color: #dee2e6;
    }

    .dx-invalid {

      .dx-label-before,
      .dx-label-after {
        border-top-color: #fa5c7c;
        border-bottom-color: #fa5c7c;
        border-inline-start-color: #fa5c7c;
        border-inline-end-color: #fa5c7c;
      }
    }
  }
}

.dx-button.dx-button-mode-contained.dx-button-default {
  &.kakao {
    background-color: #FEE500;
    color: rgba(0, 0, 0, .85);

    &.dx-state-hover {
      background-color: #f1db11;
    }
  }

  &.naver {
    background-color: #03C75A;
    color: #fff;

    &.dx-state-hover {
      background-color: #13bd5f;
    }
  }
}


.fw-button-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;

  i {
    margin: 4px 8px 0 0;
    width: 18px;
    height: 18px;
  }
}

.sns-login-devider {
  width: 100%;
  text-align: center;
  margin: 20px 0 10px;
  z-index: 10;
  color: rgba(0, 0, 0, .77);
  font-size: 15px;
}

.screen-x-small {
  .accont-card {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: none;
    border-radius: 0;

    .card-header {
      padding: 80px 24px;
    }
  }
}

.card-body-wave {
  background-image: url("data:image/svg+xml,%3Csvg width='800' height='99' viewBox='0 0 800 99' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M799.998 60.5001C644.5 60.5001 595.051 -0.124807 394.5 0.000193033C194.5 0.000193033 144.5 60.5001 0 60.5001V99H800C800 99 799.998 75.0001 799.998 60.5001Z' fill='%2354C2BA'/%3E%3C/svg%3E%0A");
  position: absolute;
  width: 3200px;
  top: -5px;
  left: 0;
  height: 99px;
  transform: translate3d(0, 0, 0) rotateX(180deg);
  animation: wave 7s ease infinite;

  &:nth-child(2) {
    top: -30px;
    animation: swell 4s ease infinite;
    opacity: .5;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -800px;
  }
}

@keyframes swell {

  0%,
  100% {
    transform: translate(0, -12px);
  }

  50% {
    transform: translate(0, 0);
  }
}

.auth-temp-mess {
  font-size: 16px;  
  text-align: center;
  color: rgba(255, 255, 255, 1);
  transform: translateY(-26px);
  margin-bottom: 40px;
}

.auth-ways-mess {
  font-size: 20px;  
  text-align: center;
  color: rgba(255, 255, 255, 1);
  transform: translateY(-20px);
}

.auth-term-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  position: relative;

  .term-title {
    font-size: 26px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    transform: translateY(-40px);
  }

  .term-detail {
    font-size: 16px;
    color: rgba(255, 255, 255, .87);
    transform: translateY(-36px);
  }

  .term-all-check {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 50px 0 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    .all-check-mes {
      font-size: 18px;
    }
  }

  .term-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    padding: 20px 0 10px;
  }

  .term-type {
    font-weight: bold;
    color: var(--primary-accent-color);

    &.requ {
      color: #fa5c7c;
    }
  }

  .term-name {
    margin-left: 8px;
  }

  .term-memo {
    overflow-y: auto;
    width: 100%;
    height: 220px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, .2);
  }

  .dx-checkbox {
    margin-right: 8px;
  }
}

.wait-wrapper {
  position: relative;
  z-index: 10;

  .wait-header {
    text-align: center;
    font-size: 24px;
    transform: translateY(-20px);
    font-weight: 600;
    color: #fff;
  }

  .wait-body {
    margin-top: 70px;

    .body-mess {
      text-align: center;
      font-size: 16px;

      strong {
        color: var(--primary-accent-color);
      }
    }

    .body-warn {
      margin-top: 36px;
      padding: 0 10px;

      .warn-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 1;
        color: #fa5c7c;

        i {
          margin-right: 6px;
          color: #fa5c7c;
        }
      }

      .warn-list {
        padding-inline-start: 20px;
        color: rgba(0, 0, 0, .76);
      }
    }
  }
}